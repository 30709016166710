import React from 'react';

const Home = () => (
    <div className="home-content">
        <h1>Hello! My Name is Cedric.</h1>
        <p>Welcome to My Portfolio</p>
    </div>
);

export default Home;
